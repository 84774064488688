.reset-password-page{
    .page-footer{
        justify-content: flex-end !important;
    }

    .submit-btn{
        max-width: 113px;
    }

    .ant-input-password:hover {
        border-color: var(--color-focus);
        border-right-width: 1px !important;
    }

    .ant-input-password:hover {
        border-color: var(--color-focus);
        border-right-width: 1px !important;
    }
}