body {
  margin: 0;
  font-family: var(--font-family), sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-weight-regular);
}

code {
  font-family: var(--font-family), sans-serif;;
}

#root{
  height:100%
}

@import "./theme/variables";
@import "./theme/spaces";
@import "./theme/colors";
@import "./theme/dividers";
@import "./theme/boxes";
@import "./theme/typography";
@import "./theme/mixin.scss";
