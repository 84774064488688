@import "./../../theme/mixin.scss";

.booking-summary {
    //margin: 0 0 0 1.5rem;

    p {
        margin: 0;
    }

    .ant-card {
        border-radius: var(--border-radius);
        background-color: #fafafa;
        border: none;

        .ant-card-head {
            border-bottom: none;

            .ant-card-head-title {
                font-size: 1.1rem;
                text-align: center;
                color: #343C5C;
                font-weight: var(--font-weight-bold);
            }
        }

        .ant-card-body {
            padding: 0 12px 12px 12px;
        }

    }

    .ant-collapse {
        .ant-collapse-item {
            border-bottom: 1px dashed rgba(0, 0, 0, 0.07);

            .ant-collapse-header {
                color: var(--color-text-field);
                font-weight: var(--font-weight-semi-bold);
                padding: 12px 10px;
            }

            .ant-collapse-content {
                .ant-collapse-content-box {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .ant-collapse-item:last-child {
            border-bottom: none;
        }


    }

    .job-line-row {
        padding: 10px;
        border-radius: var(--border-radius);
        background-color: #00000008;
    }

    .num-of-jobs {
        @include breakpoint(sp) {
            text-align: right;
        }
    }

    .ant-tag {
        border: none;
        background-color: #00000008;
        font-weight: var(--font-weight-bold);
        color: #343C5C;
        margin-right: 0px;
    }

    @include breakpoint(sp) {
        margin: 0 2%;
    }

    // .title-field{
    //     color: #343C5C !important;
    // }

    // .text-field{
    //     color: #6a737d !important;
    // }
}