.email-template-setup{
    .ant-input {
        border-radius: var(--border-radius-input);
        color: var(--color-input-field);
        background-color: var(--color-input-background);
        border: 1px solid var(--color-input-background);
    }

    .ant-select.ant-select-single {
        color: var(--color-input-field);

        .ant-select-selector {
            border-radius: var(--border-radius-input);
            color: var(--color-input-field);
            background-color: var(--color-input-background);
            border: 1px solid var(--color-input-background);
            padding: 3px 11px !important;
            height: auto !important;
        }

        .ant-select-arrow {
            color: var(--color-input-field);
        }
        }

        .ant-select.ant-select-disabled.ant-select-single {
        .ant-select-selector {
            background-color: var(--color-disable-input-background);
            border: 1px solid var(--color-disable-input-background);
        }
    }

    .scroll-list{
        height: 100%;
        overflow-y: auto;
    }

    .mail-editor{
        iframe{
            min-width: auto !important;
        }
          
    }

    .bjZCdh .blockbuilder-branding{
        display: none !important;
    }  

    .param-item{
        &:hover{
            background-color:var(--color-box-background);
            cursor: pointer;
        }
    }

    .button-col{
        position: relative;

        .btn-container{
            position: absolute;
            bottom: 0;
        }
    }

    .param-add-btn{
        color: var(--portal-color-primary);

        &:hover{
            cursor: pointer;
        }
    }

    .ant-tooltip-inner{
        white-space: pre-line !important;
    }
}