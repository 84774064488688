.otp-verification-modal {
    .ant-modal-header {
        text-align: center;
    }

    .row-flex-center {
        display: flex;
        justify-content: center;

        span.ant-typography {
            font-size: var(--font-size-header);
        }
    }

    .otp-input {
        padding: var(--font-size-header) 0;
    }

    .otp-btn-submit {
        padding-top: var(--font-size-header);
    }

    .otp-icon {
        font-size: 60px;
        padding-bottom: var(--font-size-header);
    }

    .otp-resend {
        padding-bottom: 5px;

        .ant-typography {
            line-height: 36px;
        }

        .btn-resend {
            padding: 0 0 0 6px;
        }
    }

    .otp-customer-service-phone {
        padding-bottom: var(--font-size-header);
    }

    .btn-submit {
        width: 50%;
        margin-bottom: 5px;
    }

    .otp-end-form {
        margin-bottom: var(--font-size-header);
    }

    .otp-btn-resend {
        padding: 0;
        line-height: 35px;
        padding-left: 5px;
    }
}