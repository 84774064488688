.menu-items {
    &.ant-menu {
        border-right: none;
        margin-top: 80px;
        .ant-menu-item {
            padding: 0 15px !important;
            margin: 5px auto !important;
            width: calc(100% - 30px) !important;
            border-radius: var(--border-radius);
            height: 48px;
            line-height: 48px;
            font-size: var(--portal-font-size-menu-items);
            color: #777C83;
            display: flex;
            align-items: center;

            .anticon {
                font-size: var(--portal-font-size-menu-items) !important;
                margin-right: 10px !important;
            }

            &::after {
                border-right: none;
            }

            &.ant-menu-item-selected {
                color: var(--portal-color-primary);
                font-weight: var(--font-weight-semi-bold);
                background-color: var(--portal-light-background);
                .anticon {
                    background-color: var(--portal-color-primary);
                }
            }
        }

        .anticon {
            font-size: var(--portal-font-size-menu-items);
            height: 1.25rem;
            width: 1.25rem;
            background-color: var(--portal-color-menu);
        }

        .ant-menu-submenu {
            margin: 5px 15px;
            .ant-menu-submenu-title {
                padding: 0 15px !important;
                margin: 5px auto !important;
                border-radius: var(--border-radius);
                height: 48px;
                line-height: 48px;
                color: var(--color-text-field);
                font-size: var(--portal-font-size-menu-items);
                display: flex;
                align-items: center;
                .ant-menu-submenu-arrow {

                    &::before,
                    &::after {
                        width: 10px;
                        background-image: linear-gradient(to right, var(--color-text-field), var(--color-text-field)) !important;
                    }

                    &::before {
                        transform: rotate(-45deg) translateX(4px) !important;

                    }

                    &::after {
                        transform: rotate(45deg) translateX(-4px) !important;
                    }
                }

            }

            &.ant-menu-submenu-open {
                background-color: var(--portal-light-sub-menu-background);
                border-radius: var(--border-radius);
                .ant-menu-submenu-title {
                    border-left: 4px solid var(--color-text-field);
                }

                &.ant-menu-submenu-selected {
                    background-color: var(--portal-light-background);

                    .ant-menu-sub.ant-menu-inline {
                        background-color: var(--portal-light-background);
                    }
                }

                .ant-menu-submenu-arrow {
                    transform: translateY(-4px);

                    &::before {
                        transform: rotate(45deg) translateX(4px) !important;
                    }

                    &::after {
                        transform: rotate(-45deg) translateX(-4px) !important;
                    }
                }

                .ant-menu-sub.ant-menu-inline {
                    background-color: var(--portal-light-sub-menu-background);
                    border-radius: var(--border-radius);
                    .ant-menu-item {
                        padding: 0 15px 0 25px !important;
                        font-size: var(--portal-font-size-sub-menu-items);
                    }
                }
            }

            

            &.ant-menu-submenu-selected {
                .anticon {
                    background-color: var(--portal-color-primary);
                }
                .ant-menu-submenu-title {
                    color: var(--portal-color-primary);
                    background-color: var(--portal-light-background);
                    font-weight: var(--font-weight-semi-bold);
                    border-left: 4px solid var(--portal-color-primary);
                    
                    .ant-menu-submenu-arrow {

                        &::before,
                        &::after {
                            background-image: linear-gradient(to right, var(--portal-color-primary), var(--portal-color-primary)) !important;
                        }
                    }
                }
            }

        }
    }

}