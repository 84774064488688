.general-setup {
    .ant-form-item.flex-auto {

        .ant-form-item-label,
        .ant-form-item-control {
            @media (max-width: 575px) {

                flex: auto;
            }
        }
    }

    .hyperlink {
        color: var(--color-text-title);

        &:hover {
            color: var(--portal-color-primary);
            cursor: pointer;
        }

        margin-left: 10px;
    }

}