@import "../../theme/mixin.scss";

.layout-preview {
    min-height: 80% !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .ant-page-header {
        padding: 16px 12px;

        .ant-page-header-heading {
            justify-content: center;

            @include breakpoint(pc) {
                justify-content: space-between;
            }
        }
    }


    .layout-container {
        margin: 10px;
        background-color: white;
        margin-bottom: 4rem;

        @include breakpoint(sp) {
            .content {
                margin: 0 2%;
            }
        }
    }


    @mixin step-indicator($text-color, $border-color, $background-color) {
        border-color: $border-color;
        color: $text-color;
        background-color: $background-color;
    }

    .k-stepper {
        margin: 2rem 0 1rem 0;

        .k-step-indicator {
            @include step-indicator(var(--color-text-title), var(--color-input-background), var(--color-input-background))
        }

        .k-step-label {
            color: var(--color-text-title);
        }

        .k-step-current,
        .k-step-done {
            .k-step-indicator {
                @include step-indicator(#ffffff, var(--color-primary), var(--color-primary))
            }

            // .k-step-label {
            //     color: var(--color-primary);
            // }

            .k-i-osb {
                background-color: white;
            }
        }

        .k-progressbar {
            height: 1px;
            background-color: #f0f0f0;

            .k-state-selected {
                @include step-indicator(#ffffff, var(--color-primary), var(--color-primary))
            }
        }

        .k-step-focus .k-step-indicator::after {
            display: none;
        }

        .k-step-current .k-step-indicator::after {
            display: block;
        }

        @include breakpoint(tab) {
            .k-step-indicator {
                width: 32px;
                height: 32px;
            }

            .k-i-osb {
                height: 1rem;
                width: 1rem;
            }
        }

        @include breakpoint(pc) {
            margin: 2rem 0 1rem 0;

            .k-step-indicator {
                width: 44px;
                height: 44px;
            }

            .k-progressbar {
                top: 23px;
            }

            .k-i-osb {
                height: 1.3rem;
                width: 1.3rem;
            }
        }


    }

    .ant-layout-footer {
        position: fixed;
        z-index: 10;
        width: inherit;
        padding: 0.5rem 0;
        background: white;
        height: auto;
        line-height: normal;
        text-align: center;
        bottom: 0;
        color: var(--color-text-title);
        font-weight: var(--font-weight-semi-bold);
        font-size: 15px;
        @include breakpoint(pc) {
            padding: 1rem 0;
            font-size: 16px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .layout {
        width: 90%;

        .summary-column {
            padding-left: 1.5rem;
        }

        .summary-expand {
            animation: expand 0.2s;
            float: right;
            width: 100%;
        }

        @keyframes expand {
            from {
                width: 50%;
            }

            to {
                width: 100%;
            }
        }

        .summary-collapse {
            animation: collapse 0.2s;
            float: right;
            width: 100%;
        }

        @keyframes collapse {
            from {
                width: 200%;
            }

            to {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 1600px) {
    .layout {
        width: 60%;
    }
}