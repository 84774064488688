@import "./../../theme/mixin.scss";

.layout-login.layout-login {
    background-image: url("../../assets/images/16741.jpg");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(pc) {
        justify-content: start;
    }

    .page-container {
        background-color: #fafafa;
        width: 90%;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
        border-radius: 15px;
        padding: 20px;
        max-height: 94%;
        overflow-y: auto;

        @include breakpoint(pc) {
            margin-top: 10%;
            width: 400px;
        }

        @include breakpoint(tab) {
            width: 400px;
        }

        @include mobile-landscape(767px) {
            width: 400px;
        }
    }

    .config-icon {
        text-align: right;
        .anticon {
            font-size: 1.4rem;
            color: var(--portal-color-primary);
            
            &:hover {
                cursor: pointer;
            }
        }
    }

    .page-title {
        font-size: 1.2rem;
        text-align: center;
        margin: 0 0 15px;
        color: var(--color-text-title);
        font-weight: var(--font-weight-semi-bold);
        vertical-align: middle;
        line-height: 1.3rem;

        .back-icon {
            font-size: 1rem;
            float: left;
            color: var(--portal-color-primary);

        }
    }

    .page-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .ant-btn {
            height: 36px;
            &.submit-btn {
                margin-left: 10px;
                width: calc(100% - 10px);
            }
        }
    }

    .primary-btn,
    .primary-btn:hover,
    .primary-btn:focus {
        @include btn-primary-color(var(--portal-color-primary));
    }

    .login-header {
        display: flex;
        align-items: center;
        background: transparent;

        .right-component {
            display: flex;
            float: right;
            margin-left: auto;
            overflow: hidden;
        }
    }

    .custom-dropdown  {
        height: 38px;
        color: white !important;
        background-color: transparent !important;
        border-color: var(--color-input-background) !important;
        border-radius: var(--border-radius) !important;
        .ant-select-selector {
            color: white !important;
            background-color: transparent !important;
            border: 1px solid var(--color-input-background) !important;
            padding: 3px 11px !important;
            height: auto !important;
            border-radius: var(--border-radius) !important;
        }
        .ant-select-arrow {
            color: white !important;
        }
    }
}