.ant-card.extra-operation-card {
    border-radius: var(--border-radius);

    .ant-card-body {
        padding: 10px;
    }

    .ant-tag {
        border-radius: var(--border-radius);
        font-size: 14px;
        padding: 3px 8px;
        color: #343C5C;
        background-color: #e4f2ff;
        display: flex;
        align-items: center;
        border: none;
        .anticon-close {
            //font-size: 11px;
            color: #343C5C;
        }
    }
}