@import "./../../theme/mixin.scss";
.vehicle-details {
    
    box-shadow: var(--card-box-shadow);
    .ant-card-body {
        padding: 20px 15px 15px;
        min-height: 200px;
        @include breakpoint(sp) {
            padding: 10px 0;
        }
    }

    .ant-row {
        padding: 0px 50px;
        @include breakpoint(sp) {
            padding: 0px 30px;
        }

        &.title {
            padding-right: 0;
            @include breakpoint(sp) {
                padding-right: 10px;
            }
        }
    }

    .text-field {
        color: var(--color-input-field);
    }

    .no-vehicle-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ant-btn.ant-btn-icon-only{
        width: 34px;
        height: 34px;
        border: none;
        padding: 5px;
    }
}
