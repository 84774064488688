
@import "./../../theme/mixin.scss";
.login-page {
    .login-btn, .login-btn:hover {
        height: 40px;
        padding: 4px 38px;
    }

    .forgot-pw {
        color: var(--color-text-field);
        &:hover {
            color: var(--portal-color-primary);
            cursor: pointer;
        }
    } 

    .ant-input-password:hover {
        border-color: var(--color-focus);
        border-right-width: 1px !important;
      }
}
