.confirm-email-page{
    .success-text{
        font-size: 1rem;
        margin-top: 20px;
    }

    .hyperlink {
        color: var(--portal-color-primary);
        &:hover {
            //color: var(--portal-color-primary);
            cursor: pointer;
        }
    } 
}