.theme-setup{
    @media (max-width: 575px){
        .ant-col-18 {
            flex: 0 0 85% !important;
            max-width: 85% !important;
        }
        .ant-col-6 {
            flex: 0 0 15% !important;
            max-width: 15% !important;
        }
    }    
    @media (max-width: 768px){
        .first-col{
            display: block;
        }

        .vertical-divider{
            visibility: hidden;
        }
    }

    @media (min-width: 769px){
        .first-col{
            display: flex;
        }

        .vertical-divider{
            height: 100%;
        }

        .horizontal-divider{
            visibility: hidden;
            margin: 0px;
        }
    }
    
    .preview-title{
        font-size: var(--font-size-header);
    }
}