.color-picker{
    position: relative;
    
    .button-pick{
        visibility: visible !important;
        border: 1px solid gray !important;
    }   

    .picker{
        position: absolute;   
        z-index: 1000;   
    }

    @media (max-width: 768px){
        .picker{
            transform: translateX(-85%);  
        }
    }
}