@import "./../../theme/mixin.scss";

.portal-layout.ant-layout {
    height: 100vh;

    .ant-layout-sider {
        background: white;
        box-shadow: 0 -5px 4px #00152914;
    }

    .ant-layout-header {
        background: #fff;
        padding: 0;
        position: fixed;
        z-index: 999;
        width: 100%;

        .global-header {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 16px;
            background: #fff;
            box-shadow: 0 1px 4px #00152914;

            &--left-component {
                flex: 1 1 0%;
                align-items: center;
                display: flex;
            }

            &--right-component {
                display: flex;
                float: right;
                margin-left: auto;
                overflow: hidden;
            }

            &--logo {
                padding: 0 10px;

                img {
                    height: 30px;
                }

                @include breakpoint(pc) {
                    width: 240px;

                    img {
                        height: 35px;
                    }
                }

            }
        }

        .trigger {
            font-size: 18px;
            padding: 0 5px;
            cursor: pointer;
            transition: color 0.3s;

            @include breakpoint(pc) {
                padding: 0 24px;
            }
        }

        .trigger:hover {
            color: var(--color-primary);
        }

        height: 56px;

        @include breakpoint(pc) {
            height: 64px;
        }
    }


    .site-layout {
        background-color: #F4F6FA;
        height: 100%;

        @include breakpoint(sp) {
            background-color: white;
        }

        .ant-layout-content {
            margin-top: 64px;

            @include breakpoint(sp) {
                min-width: 300px;
                margin-top: 56px
            }

            @include breakpoint(tab) {
                margin-top: 56px
            }
        }

        &--header {
            background: #fff;
            display: flex;
            align-items: center;
            padding: 0;
            box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
            min-width: 300px;
            position: fixed;
            width: 100%;

            @include breakpoint(pc) {
                &.collapsed {
                    width: 100%;
                }

                width: calc(100% - 280px);
            }


            z-index: 1;
            justify-content: space-between;
        }

        &--breadcrumb {
            color: var(--color-text-title);
            font-size: var(--portal-font-size-breadcrumb);
            font-weight: var(--font-weight-bold);
            @include breakpoint(sp) {
                margin-top: 15px;
            }
        }

        &--content {
            background: #fff;
            margin: 20px 20px;
            padding: 15px 20px 20px;
            min-height: 280px;
            @include breakpoint(tab) {
                padding: 15px;
                margin: 10px;
            }
            
            @include breakpoint(sp) {
                margin: 0 20px;
                padding: 0;
            }

            @include breakpoint(tab) {
                margin: 15px 20px;
            }
        }

    }

    .action-btn-group {
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(sp) {
            margin-bottom: 5px;
        }
    }

    .primary-btn {
        color: white;
        background-color: var(--portal-color-primary);
        border: 1px solid var(--portal-color-primary);
        height: 36px;
        font-size: 1rem;

        @include breakpoint(sp) {
            padding: 5px 8px;
        }
    }

    .ant-btn {
        height: 36px !important;
        font-size: 1rem;
    }

    .custom-dropdown  {
        height: 38px;
        color: var(--color-text-title) !important;
        background-color: var(--color-input-background) !important;
        border-color: var(--color-input-background) !important;
        border-radius: var(--border-radius) !important;
        .ant-select-selector {
            color: var(--color-input-field) !important;
            background-color: var(--color-input-background) !important;
            border: 1px solid var(--color-input-background) !important;
            padding: 3px 11px !important;
            height: auto !important;
            border-radius: var(--border-radius) !important;
        }
    }
}