@import "./../../theme/mixin.scss";

.select-customer-page {
    // background-image: url("../../assets/images/16741.jpg");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    .container-fluid {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }

    .service-selection {
        .owner-text {
            font-style: normal;
            color: #ffffff;
            font-size: 25px;
            line-height: 1.2;
            letter-spacing: 4px;
            text-align: center;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }

    .service {
        margin-top: 2rem;

        .service-section {
            padding: 20px 15px;
            background-color: #fffffff0;
            border-radius: var(--border-radius-input);
            border: 3px solid var(--color-primary);
            text-align: center;

            @include breakpoint(sp) {
                padding: 15px;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
            }

            .anticon {
                font-size: 38px;
                color: white;
                padding: 12px;
                border-radius: 50%;
                background-color: var(--color-primary);

                @include breakpoint(tab) {
                    font-size: 30px;
                }

                @include breakpoint(sp) {
                    padding: 10px;
                    font-size: 20px;
                }
            }

            p {
                font-size: 20px;
                padding: 5px 0;
                margin-bottom: 0;

                @include breakpoint(sp) {
                    padding-left: 10px;
                    text-align: left;
                }
            }
        }

        .service-section:hover {
            cursor: pointer;
        }

        .location-section {
            padding: 20px 15px;
            background-color: #fffffff0;
            border-radius: var(--border-radius-input);
            width: 100%;

            .title {
                font-size: 16px;
                color: var(--color-primary);
            }
        }
    }
}
