.location-modal{
    height: 60%;
    
    .ant-picker{
        width: 100%;
    }

    .ant-input-number{
        width: 100%;
    }

    .ant-tabs-tab-btn{
        outline: none;
    }

    .action-btn-group {
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
    }

    .primary-btn {
        color: var(--portal-color-primary);
        //background-color: var(--portal-color-primary);
        border: 1px solid var(--portal-color-primary);
        //height: 36px;
        font-size: 0.8rem;
    }
}