@import './../../theme/mixin.scss';

.booking-management {
    .overview {
        padding: 10px 15px;
        border-radius: 10px;
        margin-bottom: 15px;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        position: relative;
        svg {
            height: 38px;
            width: 38px;

            path {
                fill: var(--portal-color-green);
            }
        }

        h6 {
            color: #1c1c1c;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: var(--font-weight-semi-bold);
        }

        h5 {
            font-size: 18px;
            font-weight: var(--font-weight-semi-bold);
        }

        h4 {
            color: var(--portal-color-green);
            font-weight: var(--font-weight-semi-bold);
            margin-bottom: 5px;
            font-size: 22px;
            line-height: 22px;
        }

        .completed-status {
            h4 {
                color: var(--portal-color-grey);
            }

            svg {
                path {
                    fill: var(--portal-color-grey);
                }
            }
        }

        .cancelled-status {
            h4 {
                color: var(--portal-color-red);
            }

            svg {
                path {
                    fill: var(--portal-color-red);
                }
            }
        }

        .pending-status {
            h4 {
                color: var(--portal-color-blue);
            }

            svg {
                path {
                    fill: var(--portal-color-blue);
                }
            }
        }

        .ant-row .ant-col {
            @include breakpoint(pc) {
                border-right: 1px solid #E6E6E6;

                &:last-child,
                &:first-child {
                    border-right: none;
                }
            }
            cursor: pointer;
        }

        .loading-icon {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                height: 20px;
                width: 20px;
            }
        }

        .selected {
            div {
                border-bottom: 2px solid var(--portal-color-primary);
            }
        }
    }

    .custom-table {


        .ant-table-body {
            overflow: auto auto !important;
            height: calc(100vh - 335px) !important;
            max-height: calc(100vh - 335px) !important;

            @include breakpoint(tab) {
                height: calc(100vh - 410px) !important;
                max-height: calc(100vh - 410px) !important;
            }
        }
    }

    .action-icon {
        &.delete-icon {
            svg path {
                fill: var(--portal-color-red);
            }
        }
    }
}