.service-modal{
    .title{
        font-weight: 800;
    }
}

.editable-field{
    display: flex;
    align-items: center;
    .text-field{
        margin-right: 7px;
    }
}