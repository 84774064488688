@import './../../theme/mixin.scss';

.invalid-page {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    img {
        height: 120px;
        @include breakpoint(sp){
            height: 80px;
        }
    }
    .text-value {
        color: var(--color-text-title);
        font-size: 2.3rem;
        font-weight: var(--font-weight-bold);
        @include breakpoint(sp){
            font-size: 1.8rem;
        }
    }

    .description {
        color: var(--color-text-title);
        font-size: 1rem;
    }
}