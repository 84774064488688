$type: (color,background, border-color);
@each $item in $type { 
    .#{$item} {
        &-primary{
            #{$item}: var(--color-primary) !important; 
        }
        &-secondary{
            #{$item}: var(--color-secondary) !important; 
        }
        &-background{
            #{$item}: var(--color-background) !important; 
        }
        &-logo{
            #{$item}: var(--color-logo) !important; 
        }
        &-box-background{
            #{$item}: var(--color-box-background) !important; 
        }
        &-border{
            #{$item}: var(--color-border) !important; 
        }
        &-highlight{
            #{$item}: var(--color-highlight) !important; 
        }
    } 
} 
