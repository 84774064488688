$spaceamounts: (1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
@each $item in $spaceamounts { 
    .hr-#{$item} {
        width: 100%;
        height: rem($item);
        display: block; 
        margin: 0;
        padding: 0;  
    }
}

$borders: (top, bottom, left, right);
@each $item in $borders {
    .b{
        &-#{str-slice($item, 0, 1)}{
            border-#{$item}-width: 1px;
            border-#{$item}-style: solid; 
        }
    }
}