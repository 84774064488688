.full-height{
    height: 100%;
}

.full-width{
    width: 100%;
}

$sizes: (5, 10, 15, 16, 20, 25, 30, 32, 35, 38, 40, 45, 50, 65, 75, 100);

@each $size in $sizes {
    .w-#{$size} {
        width: #{$size}px !important;
    }
    .h-#{$size} {
        height: #{$size}px !important;
    }
}

.align-center {
    align-items: center;
}

.flex-center {
    display: flex !important;
    align-items: center;
}

.no-display {
    display: none !important;
}

.mobile-hidden {
    @media only screen and (max-width: 767px) {
        display: none !important;
    }
}

.justify-end {
    justify-content: end;
}