.dealership-selection-page{
    .ant-card {
        border-radius: var(--border-radius);
        background-color: #fafafa;
        border: none;

        .ant-card-head {
            border-bottom: none;

            .ant-card-head-title {
                font-size: 1.1rem;
                text-align: center;
                color: #343C5C;
                font-weight: var(--font-weight-bold);
            }
        }

        .ant-card-body {
            padding: 0 12px 12px 12px;
        }

    }
}