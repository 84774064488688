$spaceamounts: (0, 5, 10, 15, 16, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
    .m-#{$space}{
        margin: #{$space}px !important;
    }
    .p-#{$space}{
        padding: #{$space}px !important;
    }
}

.flex-items {
  display: flex !important;
  align-items: center !important;
}

.align-space-between {
  justify-content: space-between !important;
}

.flex-display {
  display: flex !important;
}
