@import "./../../../theme/mixin.scss";

.status-tag {
    // background-color: #fff2f2;
    color: #ff4d4f;
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // border: 1px solid #ff4d4f;
    border-radius: 5px;
    min-width: 72px;
    display: inline-block;
    // padding: 1px;
    text-align: center;

    @include breakpoint(sp) {
        font-size: 16px;
        padding: 0;
        min-width: 60px;
    }

    &.active-tag {
        // background-color: #edfcf6;
        color:  var(--portal-color-green);
        // border: 1px solid #00B368;
    }

    &.pending-tag {
        // background-color: #edfcf6;
        color: var(--portal-color-blue);
        // border: 1px solid #00B368;
    }
    &.complete-tag {
        // background-color: #edfcf6;
        color: var(--portal-color-grey);
        // border: 1px solid #00B368;
    }

    &.confirm-tag {
        // background-color: #edfcf6;
        color: #14ACFA;
        // border: 1px solid #00B368;
    }
}

.grid-action-btn {
    .ant-btn-icon-only.ant-btn-sm {
        width: 30px !important;
        height: 30px !important;
        background-color: var(--portal-light-background);
        color: var(--portal-color-primary);
        border: 1px solid var(--portal-light-background);
        margin-right: 5px;
        margin-bottom: 5px;

        &.confirmed-button
        {
            color: var(--portal-color-green)!important;
            background-color: var(--portal-color-light-green);
            border: 1px solid var(--portal-color-light-green);
        }

        &.start-button
        {
            color: var(--portal-color-blue)!important;
            background-color: var(--portal-color-light-blue);
            border: 1px solid var(--portal-color-light-blue);
        }

        &.delete-button
        {
            color: var(--portal-color-red)!important;
            background-color: var(--portal-color-light-red);
            border: 1px solid var(--portal-color-light-red);
        }
    }
    
}

.cell-hover:hover{
    cursor: pointer;
    label{
        cursor: pointer;
    }
}