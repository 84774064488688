// @font-face {
//     font-family: 'ProximaNovaAltLight';
//     font-style: normal;
//     font-weight: 200;
//     src: url('./fonts/ProximaNovaAlt-Regular.ttf');
// }

// @font-face {
//     font-family: 'ProximaNovaAlt';
//     font-style: normal;
//     font-weight: normal;
//     src: url('./fonts/ProximaNovaAlt-Regular.ttf');
//   }
//   @font-face {
//     font-family: 'ProximaNovaAltSemiBold';
//     font-style: normal;
//     font-weight: 500;
//     src: url('./fonts/ProximaNovaAlt-Semibold.ttf');
//   }
//   @font-face {
//     font-family: 'ProximaNovaAltBold';
//     font-style: normal;
//     font-weight: bold;
//     src: url('./fonts/ProximaNovaAlt-Bold.ttf');
//   }

:root {
    --color-primary: #1890ff;
    --color-secondary:#37455E;
    --color-focus: #40a9ff;
    --color-active: #096dd9;

    --color-background: #fff;
    --color-logo: rgba(255, 255, 255, 0.2);
    --color-box-background: #EAEAEB;
    --color-border: #58595B;
    --color-highlight: #F4801F;
    --color-input-background: #F3F6F9;
    --color-disable-input-background: #ededed;


    --border-radius-form: 50px;

    --font-family-light: 'ProximaNovaAltLight';
    --font-family-regular: 'ProximaNovaAlt';
    --font-family-semi-bold: 'ProximaNovaAltSemiBold';
    --font-family-bold: 'ProximaNovaAltBold';

    --font-family: 'Nunito';
    --font-weight-light: 400;
    --font-weight-regular: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    
    --font-heading1-color: #000000;
    --font-heading2-color: #000000;
    --font-heading3-color: #58595b;
    --font-body-color: #58595b;
    --font-navigation-color: #F4801F;

    --color-text-title: #343C5C;
    --color-text-field: #5c646c;
    --color-input-field: #222529;
    --color-disable-input-field: #222529a6;
    --color-error-field: #ff4d4f;
    --color-button-default-text: rgba(0, 0, 0, 0.65);
    --color-button-default-border: #d9d9d9;

    --font-size-text-field: 16px;
    --font-size-header: 18px;
    
    --border-radius: 4px;
    --border-radius-input: 9px;
    --border-radius-popup: 15px;

    --slider-width-pc: 150px;
    --slider-width-tab: 110px;
    --slider-width-sp: 85px;

    // ---------Portal variables---------//
    --portal-color-primary: #f58020;
    --portal-color-secondary: #14ACFA;
    --portal-light-background: #ffede6;
    --portal-light-sub-menu-background: #f0f2f56e;
    --portal-color-menu: #777C83;
    --portal-color-green: #00B368;
    --portal-color-light-green: #baffe280;
    --portal-color-red: #ff4d4f;
    --portal-color-light-red:#fdc8c8d4;
    --portal-color-blue: rgb(8, 102, 209);
    --portal-color-light-blue: #e4f7ff;
    --portal-color-grey: #70797f;
    --portal-color-yellow: #FFB302;

    --portal-layout-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    --portal-font-size-menu-items: 1rem;
    --portal-font-size-sub-menu-items: 0.95rem;
    --portal-font-size-breadcrumb: 1.15rem;
    --card-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
}