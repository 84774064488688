//============================================
// breakpoint
//============================================
@mixin breakpoint($point) {
	@if $point==pc {
		@media only screen and (min-width: 1024px) {
			@content ;
		}
	}

	@else if $point==tab {
		@media screen and (max-width: 1023px) and (min-width: 768px) {
			@content ;
		}
	}

	@else if $point==sp {
		@media only screen and (max-width: 767px) {
			@content ;
		}
	}
}

@mixin mobile-landscape($max-width) {
	@media (max-width: $max-width) and (orientation:landscape) {
		@content;
	}
}

//============================================
// button
//============================================

@mixin btn-primary-color($color) {
	color: white;
	background-color: $color;
	border: 1px solid $color;
}

@mixin btn-default-color() {
	color: var(--color-button-default-text);
	background-color: white;
	border: 1px solid var(--color-button-default-border);
}