@import "./theme/mixin.scss";

:root {
  --modal-height-scroll-list: 100%;
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
}

// .layout {
//   min-height: 100% !important;
//   .ant-layout-header {
//     height: auto;
//     line-height: normal;
//     margin: 2% 0;
//     padding: 0 10px;
//     @include breakpoint(sp) {
//       margin: 4% 0;
//     }
//   }
// }

.show {
  display: block;
}

.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000003a;
  transition: opacity 0.2s ease;
}

.modal {
  width: 500px;
  position: relative;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  transform: translateY(100%);
  transition: transform 0.2s ease;
  box-shadow: 0 2px 8px 3px;
  font-family: Helvetica, Arial, sans-serif;
}

.modal button {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 1px 1px 1px #0000003a;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.562);
}

.app_title {
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-text-title);
  font-size: 25px;
  line-height: 1.1;
  letter-spacing: 3px;
  margin-bottom: 0;
  text-transform: uppercase;

  @include breakpoint(tab) {
    font-size: 20px;
  }

  @include breakpoint(sp) {
    font-size: 13px;
  }
}

.divider-line {
  text-align: center;

  hr {
    padding: 0 15px;
    max-width: 100%;
    width: 10%;
    border: 2px solid var(--color-primary);
    margin: 0;
    display: inline-block;

    @include breakpoint(sp) {
      width: 25%;
    }

    &.small-line {
      width: 5%;

      @include breakpoint(sp) {
        width: 20%;
      }
    }
  }
}

.btn {
  text-shadow: none !important;
  box-shadow: none !important;
  height: 38px !important;
  border-radius: var(--border-radius) !important;
}

.input-base {
  border-radius: var(--border-radius-input);
  color: var(--color-input-field);
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-background);
}

.ant-form {
  .ant-col {
    &.ant-form-item-label {
      padding: 0;

      label {
        margin-left: 10px;
        color: var(--color-text-title);
      }
    }

    .disable-item {
      .ant-form-item-label {
        label {
          color: var(--color-disable-input-field);

          &::before {
            color: var(--color-disable-input-field);
          }
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    color: var(--color-text-title);
  }

  .ant-form-item {
    margin-bottom: 10px;

    @include breakpoint(sp) {
      margin-bottom: 5px;
    }

    &.disable-modal {
      .ant-form-item-control-input {
        .ant-input-affix-wrapper {
          background-color: var(--color-disable-input-background);
          border-color: var(--color-disable-input-background);
        }
      }
    }

    &.dropdown-modal {

      &.ant-form-item-has-error {
        .ant-input-affix-wrapper {
          border: 1px solid #ff4d4f;
        }

        .ant-input,
        .ant-input:hover {
          background-color: var(--color-input-background);
          border: none;
        }
      }

      .ant-input-affix-wrapper-disabled {
        background-color: var(--color-disable-input-background);
      }
    }
  }

  .ant-input {
    @extend .input-base;
    padding: 6px 11px;
    line-height: 1.5;
  }

  .ant-input[disabled] {
    color: var(--color-input-field);
    background-color: var(--color-disable-input-background);
    border: 1px solid var(--color-disable-input-background);

    &:hover {
      border: 1px solid var(--color-disable-input-background);
    }
  }

  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input:hover {
    background-color: var(--color-input-background);
    border: 1px solid #ff4d4f;
  }

  .ant-form-item-control-input {
    .ant-input-affix-wrapper {
      height: unset;
      @extend .input-base;
      padding: 0 11px;

      .anticon {
        svg {
          height: 12px;
          width: 12px;
        }
      }

      .ant-input-disabled:disabled {
        background-color: var(--color-disable-input-background);
        border: 1px solid var(--color-disable-input-background);
      }
    }

    .ant-form-item-control-input-content {
      width: 100%;

      .ant-input-number {
        width: 100%;
        @extend .input-base;
        padding: 3px 0 !important;

        .ant-input-number-handler-wrap {
          border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0;
          border-left: none;

          &:hover .ant-input-number-handler {
            height: 50% !important;
          }
        }

        .ant-input-number-handler-wrap .ant-input-number-handler {
          border: 1px solid #0000000d;

          &:hover {
            height: 50% !important;

            .anticon {
              color: var(--portal-color-primary);
            }
          }

          &.ant-input-number-handler-up {
            border-radius: 0 var(--border-radius-input) 0 0;

          }

          &.ant-input-number-handler-down {
            border-top: none;
            border-radius: 0 0 var(--border-radius-input) 0;
          }
        }

        &.ant-input-number-disabled {
          background-color: var(--color-disable-input-background);
          border: 1px solid var(--color-disable-input-background);
        }
      }

      .ant-input-search {
        .ant-input-group-addon {
          background-color: var(--color-input-background);
          border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0;

          .ant-btn,
          .ant-btn:focus {
            height: 38px;
            border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0 !important;
            padding: 0px 12px;
            font-size: 16px;
            border: none;
          }
        }

        .ant-input-wrapper {
          border-radius: var(--border-radius-input);

          .ant-input-affix-wrapper,
          .ant-input-affix-wrapper:hover,
          .ant-input-affix-wrapper:focus {
            padding: 0;

            .ant-input {
              border: none;
              padding-left: 11px;
            }
          }
        }
      }
    }
  }

  .ant-select.ant-select-single:not(.ant-select-customize-input) {
    color: var(--color-input-field);

    .ant-select-selector {
      @extend .input-base;
      padding: 3px 11px !important;
      height: auto !important;
    }

    .ant-select-arrow {
      color: var(--color-input-field);
    }

    &.ant-select-auto-complete {
      .ant-select-selector {
        //padding: 7px 11px !important;
        height: 38px !important;
        .ant-select-selection-search {
          //padding: 4px 0;

          input {
            //padding: 7px 0px;
            margin-top: 3px;
          }
        }
      }
    }
  }

  .ant-select.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: var(--color-disable-input-background);
      border: 1px solid var(--color-disable-input-background);
    }
  }

  .ant-select-multiple .ant-select-selector {
    @extend .input-base;
    padding: 4px 11px !important;
    height: auto !important;

    .ant-select-selection-item {
      border-radius: var(--border-radius);
      background-color: var(--portal-color-secondary);
      border: 1px solid var(--portal-color-secondary);

      .ant-select-selection-item-content,
      .ant-select-selection-item-remove {
        color: white;
      }
    }
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-color: var(--color-focus) !important;
  }
  .footer-item {
    text-align: right;
    margin-top: 10px;
  }

  .ant-picker {
    width: 100%;
    @extend .input-base;
    padding: 7px 11px !important;

    .ant-picker-clear {
      background: var(--color-input-background);
    }

    .ant-picker-input>input,
    .ant-picker-suffix {
      color: var(--color-input-field);
    }
  }

}

.ant-modal {

  .ant-modal-content {
    border-radius: var(--border-radius-popup);

    .ant-modal-title {
      font-size: var(--font-size-header);
      color: var(--color-text-title);
    }

    .ant-modal-header {
      border-radius: var(--border-radius-popup) var(--border-radius-popup) 0 0;
      border-bottom: 0;
    }

    .ant-modal-body {
      padding: 15px 24px;
      max-height: calc(100vh - 120px);
      height: calc(100vh - 120px);
      overflow-y: auto;
      @include breakpoint(pc) {
        max-height: calc(100vh - 220px);
        height: auto;
      }
      .ant-list-items {
        margin: 10px 0;
        // max-height: calc(100vh - 350px);
        // overflow-y: auto;

        .ant-list-item {
          border-bottom: none;
        }
      }
    }

    .ant-modal-footer {
      border-top: none;

      .ant-btn {
        height: 38px;

        &:hover {
          @include btn-default-color()
        }

        border-radius: var(--border-radius);

        &.ant-btn-primary,
        &.ant-btn-primary:hover {
          @include btn-primary-color(var(--color-primary))
        }
      }

    }
  }

  @include breakpoint(sp) {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;

    .ant-modal-content {
      height: 100vh;
      border-radius: 0 !important;
    }

    .ant-modal-body {
      height: calc(100vh - 220px);
    }

    .ant-modal-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }

  &.portal-modal {
    .ant-modal-content {
      .ant-btn {

        &.ant-btn-primary,
        &.ant-btn-primary:hover {
          @include btn-primary-color(var(--portal-color-primary))
        }
      }
    }
  }

  &.vertical-modal {
    margin: 0 0 0 auto;
    height: 100vh;

    .ant-modal-content {
      height: 100%;
      border-radius: 0;

      .ant-modal-body {
        max-height: unset;
      }
    }

    &.no-footer {
      .ant-modal-content {
        .ant-modal-body {
          height: calc(100vh - 80px);
        }
      }
    }

    &.modal-open {
      animation: open 0.3s;
    }

    @keyframes open {
      from {
        left: 400px;
      }

      to {
        left: 0;
      }
    }
  }

  &.vehicle-modal {
    .area-code-input {
      display: flex;

      .area-item {
        flex-grow: 3;
        flex-basis: 100px;
        margin-right: 4px;
      }

      .code-item {
        flex-grow: 2;
        flex-basis: 70px;
        margin: 0 2px;

        .ant-form-item-explain {
          display: none;
        }
      }
    }

    .footer-item {
      position: absolute;
      bottom: 10px;
      left: 25px;
      background-color: white;
    }
  }
}

.text-field {
  display: block;
  color: var(--color-text-field);
  font-size: var(--font-size-text-field);
  min-height: 15px;
}

.title-field {
  display: block;
  color: var(--color-text-title);
  font-size: var(--font-size-text-field);
}

.ant-card {
  width: 100%;

  &.card-selection {
    border-radius: 20px;
    border: 1px solid var(--color-input-background);
    background-color: var(--color-input-background);

    .ant-card-body {
      padding: 14px 15px;
    }

    .ant-col {
      padding: 2px 5px;
    }
  }

  &.card-selection:hover {
    border: 1px solid var(--color-focus);
  }
}

.ant-input-affix-wrapper {

  &.search-input {
    height: 38px;
    border-color: var(--color-input-background);
    border-radius: var(--border-radius-input);
    background-color: var(--color-input-background);

    .ant-input-prefix {
      margin-right: 10px;
    }
  }
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.k-i {
  &-osb {
    background-color: var(--color-text-title);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    height: 0.8rem;
    width: 0.8rem;
  }

  &-customer {
    -webkit-mask-image: url(./assets/icons/customer.png);
    mask-image: url(./assets/icons/customer.png);
  }

  &-vehicle {
    -webkit-mask-image: url(./assets/icons/car.jpg);
    mask-image: url(./assets/icons/car.jpg);
  }

  &-service {
    -webkit-mask-image: url(./assets/icons/service.png);
    mask-image: url(./assets/icons/service.png);
  }

  &-booking {
    -webkit-mask-image: url(./assets/icons/calendar.png);
    mask-image: url(./assets/icons/calendar.png);
  }

  &-summary {
    -webkit-mask-image: url(./assets/icons/confirm.png);
    mask-image: url(./assets/icons/confirm.png);
  }

  &-user-management {
    -webkit-mask-image: url(./assets/icons/user_management.png);
    mask-image: url(./assets/icons/user_management.png);
  }

  &-setting {
    -webkit-mask-image: url(./assets/icons/setting.png);
    mask-image: url(./assets/icons/setting.png);
  }

  &-location-management {
    -webkit-mask-image: url(./assets/icons/location_management.png);
    mask-image: url(./assets/icons/location_management.png);
  }

  &-service-management {
    -webkit-mask-image: url(./assets/icons/service_management.png);
    mask-image: url(./assets/icons/service_management.png);
  }

  &-booking-management {
    -webkit-mask-image: url(./assets/icons/booking_management.png);
    mask-image: url(./assets/icons/booking_management.png);
  }


}

.ant-btn {
  box-shadow: none !important;
  text-shadow: none !important;
  border-radius: var(--border-radius) !important;

  &:hover {
    opacity: 0.7;
    color: #d9d9d9;
    border-color: #d9d9d9;
  }

  &.has-icon-btn {
    .anticon+span {
      margin-left: 5px !important;
    }

    padding: 5px 8px;
  }

  &.secondary-btn {
    color: var(--color-text-title);
    background-color: var(--color-input-background);
    border-color: var(--color-input-background);
    padding: 5px 8px;
  }

  &.cancel-btn,
  &.cancel-btn:hover {
    color: var(--color-error-field);
    background-color: white;
    border-color: var(--color-error-field);
  }
}

.ant-layout-header {
  &.layout-header {
    position: fixed;
    z-index: 10;

    width: inherit;
    padding: 0.7rem 0 0 0;

    background: white;
    height: auto;
    line-height: normal;
    text-align: center;

    @include breakpoint(pc) {
      padding: 0.7rem 0;
    }

    .layout-info {
      display: flex;

      justify-content: space-between;
      align-items: center;
      padding: 0 1rem 0.7rem 1rem;
      box-shadow: inset 0 -2px #fff, 0 2px 1px rgba(0, 0, 0, 0.03);
      border-bottom: none;

      @include breakpoint(tab) {
        padding: 0 2rem 0.7rem 2rem;
      }

      @include breakpoint(pc) {
        padding: 0 0 1rem 0;
        border-bottom: 1px solid var(--color-input-background);
        box-shadow: none
      }
    }

    .logo {
      img {
        height: 35px;

        @include breakpoint(tab) {
          height: 40px;
        }

        @include breakpoint(pc) {
          height: 50px;
        }
      }
    }

    .logo:hover {
      cursor: pointer;
    }

    .btn-group {
      min-width: 35px;

      @include breakpoint(tab) {
        min-width: 40px;
      }

      @include breakpoint(pc) {
        min-width: 50px;
      }

      .ant-btn {
        .btn-description {
          display: none;

          @include breakpoint(pc) {
            display: inline-block;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      // background-color: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
  }
}

.custom-table {
  .ant-table-thead>tr>th {
    color: var(--color-text-title);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    border-bottom: none;
    background-color: #F5F8FF;
    font-size: 13px;
    padding: 16px 10px;

    @include breakpoint(tab) {
      font-size: 10px;
    }

    .ant-table-column-sorters {
      padding: 0;
    }
  }

  .ant-table-tbody>tr>td {
    border-bottom: 1px solid transparent;
    color: var(--color-input-field);
    padding: 12px 10px;
  }

  .ant-table-tbody>tr:nth-child(even) {
    background-color: #f9fafc;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    ant-table-column-sort {
      background-color: #f9fafc;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 20px 0 0;

    .ant-pagination-disabled {
      .ant-pagination-item-link {
        opacity: 0.5;

      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: unset;

    button {
      border: none;
      background-color: var(--portal-light-background);
      color: var(--portal-color-primary);
      min-width: 32px;
    }
  }

  .ant-pagination-item {
    border: none;
    background-color: var(--portal-light-background);

    a {
      color: var(--portal-color-primary);
    }
  }

  .ant-pagination-item-active {
    border: 1px solid var(--portal-color-primary);
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down,
  .ant-table-filter-trigger {
    @include breakpoint(tab) {
      font-size: 9px;
    }

    &.active {
      color: var(--portal-color-primary);
    }
  }

  .ant-table-filter-trigger {
    @include breakpoint(tab) {
      font-size: 10px;
    }
  }

  .ant-table-body {
    overflow: auto auto !important;
    height: calc(100vh - 295px) !important;
    max-height: calc(100vh - 295px) !important;

    @include breakpoint(tab) {
      height: calc(100vh - 320px) !important;
      max-height: calc(100vh - 320px) !important;
    }
  }
}

.portal-checkbox.ant-checkbox-wrapper {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--portal-color-primary);
      border-color: var(--portal-color-primary);
    }
  }
}

.ant-input-affix-wrapper-focused {
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border: 1px solid var(--color-focus) !important;
}

.ant-form .ant-form-item-has-error .ant-input-password,
.ant-form .ant-form-item-has-error .ant-input-password:hover {
  background-color: var(--color-input-background);
  border: 1px solid #ff4d4f;
}

.ant-form .ant-form-item-has-error .ant-input-affix-wrapper-focused {
  background-color: var(--color-input-background);
  border: 1px solid #ff4d4f !important;
}

.no-border .ant-input {
  border: none !important;
}


.ant-list {
  &.card-list {
    .ant-list-item {
      border-bottom: none;
    }
  }
}

@include breakpoint(pc) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    // background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.ant-modal-root {
  .ant-modal-wrap {
    display: flex;
    align-items: center;
    overflow-x: hidden;

    .ant-modal {
      top: 0;
      padding-bottom: 0;
    }
  }

  .custom-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 45px;
  }

}

.ant-picker-dropdown {

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;
    min-width: 26px;
    height: 26px;
    line-height: 26px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
  }

  &.portal-date-picker {

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: var(--portal-color-primary);
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid var(--portal-color-primary);
    }

    .ant-picker-today-btn {
      color: var(--portal-color-primary);
    }
  }
}

.ant-switch {
  &.portal-switch {
    &.ant-switch-checked {
      background-color: var(--portal-color-primary);
    }
  }
}

@include breakpoint(sp) {
  .switch-item-contain {

    .ant-form-item-label,
    .ant-form-item-control {
      max-width: 50% !important;
    }
  }

  .infinite-scroll-container {
    overflow: auto;
    max-height: calc(100vh - 155px);
  }

  .infinite-scroll-loading-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}

.infinite-scroll-loading-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}


.ant-table-filter-dropdown {
  border-radius: var(--border-radius-input);

  .ant-dropdown-menu {
    max-height: 300px !important;

    .ant-dropdown-menu-item-selected {
      background-color: transparent;
    }
  }

  .custom-filter-dropdown {
    padding: 10px 15px 0;
    border-radius: var(--border-radius-input);
    background: #fff;
  }

  .ant-table-filter-dropdown-btns {
    padding: 10px;
    border-top: none;

    &.custom-btns {
      padding: 10px 0;
    }

    .ant-btn-link {
      padding: 0;
    }

    .ant-btn-sm {
      height: 30px;
      padding: 0px 10px;
    }
  }
}

//change modal backdrop z-index to be higher than dropdown
// .ant-modal-root {
//   .ant-modal-mask {
//     z-index: 1050;
//   }

//   .ant-modal-wrap {
//     z-index: 1051;
//   }
// }

.user-info {

  @include breakpoint(sp) {
    max-width: 120px;
  }

  &:hover {
    cursor: pointer;
  }

  .ant-avatar {
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;

    @include breakpoint(pc) {
      width: 35px;
      height: 35px;
      line-height: 35px;
    }
  }

  .username {
    color: var(--color-text-title);
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: var(--font-weight-semi-bold);
  }

  &--large-item {
    .ant-avatar {
      @include breakpoint(pc) {
        width: 45px;
        height: 45px;
        line-height: 45px;
      }
    }

    .username {
      margin-left: 0;
      font-size: 1.2rem;
    }
  }
}

.user-info-popup {
  z-index: 1000 !important;

  .user-info-dropdown {
    border-right: none;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014, 0 9px 28px 8px #0000000d !important;
    border-radius: var(--border-radius);

    .ant-menu-item {
      color: var(--color-text-title);
    }

    .dealer-item {
      span {
        color: var(--color-text-title);
      }

      .ant-tag {
        border-radius: var(--border-radius);
        font-size: 1rem;
        font-weight: var(--font-weight-semi-bold);
        padding: 2px 10px;
        border: none;
      }

      &:hover {
        cursor: default;
      }
    }

    .logout-item {
      color: #40a9ff;
    }

    .ant-menu-item-selected {
      background-color: white !important;
    }
  }
}

.list-container {
  height: calc(100vh - var(--modal-height-scroll-list));

  .ReactVirtualized__List:focus {
    outline: none;
  }
}

.swiper {

  .swiper-button-next,
  .swiper-button-prev {
    color: var(--color-primary);
    width: 15px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 30px;

    @include breakpoint(sp) {
      font-size: 20px;
    }
  }
}

.wrap-text-dropdown {
  .ant-select-item-option-content {
    white-space: break-spaces;
    word-break: break-word;
  }
}

.custom-footer {
  background-color: white;

  @include breakpoint(sp) {
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    width: calc(100% - 40px);

    .ant-btn {
      width: 100%;
    }

    .ant-space {
      width: 100%;

      .ant-space-item {
        width: 50%;
      }
    }

  }
}

.avatar-container {
  position: relative;

  .avatar-uploader {
    .ant-upload-select {
      margin-right: 0;
      margin-bottom: 0;

      .ant-upload {
        img {
          height: auto;
          max-width: 102px;
          margin: 0 auto;
          max-height: 102px;

          &.no-image {
            height: 50%;
          }
        }


      }
    }

    .ant-upload-select-picture-card {
      width: 100%;
    }
  }

  .remove-logo {
    position: absolute;
    right: -15px;
    bottom: -8px;

    .ant-btn.ant-btn-circle {
      height: 32px !important;
      width: 32px !important;
      padding: 0;
      border-radius: 50% !important;
      line-height: 30px;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: white;
        }
      }
    }
  }


}

.editable-input {
  display: flex;
  align-items: center;

  form {
    display: flex;
    align-items: flex-start;

    .ant-form-item {
      &.ant-form-item-has-error {
        .ant-form-item-control-input .ant-input-affix-wrapper {
          border-color: #ff4d4f;

          input {
            border: none;
          }
        }
      }
    }
  }

  .ant-btn {
    width: 41px;
    height: 41px;
    padding: 8px 0;

    &.edit-btn {
      border: none;
    }
  }
}

.service-list {
  @include breakpoint(pc) {
    max-height: 300px;
    overflow-y: auto;
  }

  @include breakpoint(tab) {
    max-height: 300px;
    overflow-y: auto;
  }

  .ant-card {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 6px -1px, rgba(0, 0, 0, 0.05) 0px 2px 4px -1px;
    border-radius: 10px;
    cursor: pointer;

    .ant-card-body {
      padding: 12px 15px;
    }

    .flex-display {
      display: flex;
      align-items: center;

      .ant-avatar-square {
        border-radius: 8px;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        flex: 0 0 40px;

        @include breakpoint(sp) {
          height: 35px;
          width: 35px;
          margin-right: 10px;
          flex: 0 0 35px;
        }
      }
    }

    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 50%;
        border: 1px solid var(--color-text-title);
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border: 1px solid var(--color-primary);
        }
      }
    }
  }

  .ant-list-items {
    .ant-list-item {
      border-bottom: none;
      padding: 6px 0;
    }
  }

  label {
    cursor: pointer;
  }
}

.calendar-item .ant-select,
.calendar-item .ant-cascader-picker {
  width: inherit !important;
}

.ant-picker-calendar-mode-switch {
  display: none !important;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.ant-picker-calendar {
  .ant-picker-panel {
    border-top: 1px solid var(--color-input-background);
    padding-top: 10px;
  }

  .ant-picker-content {
    th {
      font-weight: var(--font-weight-bold);
      color: var(--color-text-title);
    }

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        border-radius: 50%;
      }
    }

    .ant-picker-cell-inner::before {
      border-radius: 50%;
    }

    .ant-picker-cell-inner {
      min-width: 30px;
      height: 30px;
      line-height: 31px;
    }
  }
}

.ant-tabs {
  .ant-tabs-nav {
    .ant-tabs-tab {
      text-transform: uppercase;
      @include breakpoint(sp) {
        font-size: 13px;
        margin: 0 15px 0 0;
      }
    }
  }
}

.update-booking-modal {
  .ant-modal-body {
    @include breakpoint(pc) {
      min-height: 480px;
    }
  }
}