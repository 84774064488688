.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}   
.heading-1{
    font-family: var(--font-family-semi-bold);
    font-size: 2.6rem;
    color: var(--font-heading1-color);
}
.heading-2{
    font-family: var(--font-family-light);
    font-size: 1.3rem;
    color: var(--font-heading2-color);
}
.heading-3{
    font-family: var(--font-family-regular) !important;
    font-size: 1.3rem !important;
    color: var(--font-heading3-color) !important;
}
.text-body{
    font-family: var(--font-family-semi-light);
    font-size: 1.3rem;
    color: var(--font-body-color);
}
.text-navigation{
    font-family: var(--font-family-regular);
    font-size: 1.1rem;
    color: var(--font-navigation-color);
}

.bold-text {
    font-weight: var(--font-weight-bold);
}
.semi-bold-text {
    font-weight: var(--font-weight-semi-bold);
}

.uppercase-text {
    text-transform: uppercase !important;
    input {
        text-transform: uppercase !important;
    }
}