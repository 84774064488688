.team-modal{
    .ant-picker{
        width: 100%;
    }

    .ant-tabs-tab-btn{
        outline: none;
    }

    .edit-label{
        font-size: 0.9rem;
        font-style: italic;
        color: var(--color-button-default-border);
    }
}