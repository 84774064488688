.job-modal {
    .job-line-item {
        .ant-form-item-label {
            flex: 0 0 70px !important;
        }

        .ant-form-item-control {
            flex: 0 0 150px !important;
        }
    }
}