.ant-form label,
.ant-select,
.ant-btn,
.ant-input,
.ant-collapse,
.ant-select-item,
.ant-empty,
.ant-table {
    font-size: var(--font-size-text-field);
}

.ant-btn {
    padding: 6px 15px;
    height: 36px;
}

.ant-input-search {
    input {
        height: 36px;
    }

    .ant-input-suffix {
        margin-right: 10px;
    }

    &.ant-input-affix-wrapper-disabled {
        background-color: var(--color-disable-input-background) !important;
    }
}