@import "./../../theme/mixin.scss";

.service-detail {
    margin: 0 15px;

    @include breakpoint(sp) {
        margin: 0;
    }

    .action-icon {
        padding: 10px;
        color: #929295;
    }

    .action-icon:hover {
        background-color: rgba(0, 0, 0, 0.07);
        border-radius: 50%;
    }

    .table-responsive {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.05);

        td,
        th {
            border: none;
            min-width: 25%;
            max-width: 320px;
        }

        .actions {
            width: 120px;
            min-width: 120px;
            text-align: center;
        }

        .index {
            width: 70px;
            min-width: 70px;
        }

        @include breakpoint(sp) {
            .actions {
                text-align: left;
            }
        }

        thead {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            color: var(--color-text-field);

            @include breakpoint(sp) {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            th {
                padding: 10px;
                min-height: 50px;
                font-weight: var(--font-weight-semi-bold)
            }
        }


        tbody {
            tr {
                @include breakpoint(sp) {
                    display: block;
                }

                &:nth-child(2n) {
                    background: rgba(0, 0, 0, 0.03);
                }

                td {
                    padding: 10px;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: var(--color-text-field);

                    @include breakpoint(sp) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        display: flex;
                        align-items: center;
                        text-align: left;
                        width: 100% !important;
                        position: relative;
                        padding-left: 90px;
                        min-height: 50px;
                        white-space: normal;

                        &::before {
                            width: 85px;
                            content: attr(data-label);
                            position: absolute;
                            top: 50%;
                            left: 10px;
                            transform: translateY(-50%);
                            font-weight: 600;
                            line-height: 1.2rem;
                            color: var(--color-text-title);
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }

                .title {
                    max-width: 300px;
                }
            }

        }
    }

    .ant-card {
        .table-card {
            line-height: 1;
            margin: 10px 0;
            border: none;

            .ant-card-head {
                min-height: unset;
                padding: 0 15px;

                @include breakpoint(sp) {
                    padding: 0;
                }

                .ant-card-head-title {
                    color: var(--color-text-title);
                    font-size: var(-font-size-text-field);
                }
            }

            .ant-card-body {
                padding: 15px;

                @include breakpoint(sp) {
                    padding: 15px 0;
                }
            }
        }

        &.card-details {
            .actions {
                font-size: 16px;
            }

            .anticon {
                margin-left: 1rem;
                padding: 0;
            }

        }
    }

    .advisor-item {
        margin-bottom: 5px;
    }

    .note-label {
        color: var(--color-text-field);
        font-weight: var(--font-weight-semi-bold);
        font-size: var(--font-size-text-field);
        text-align: center;
    }
}